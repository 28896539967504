import { Box, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";

import {
	TalosButton,
	TalosDateField,
	TalosDropDown,
	TalosTextBox,
} from "../forms";
import { formValidationSchema } from "./add-mw4-flow-form.validation-schema";
import {
	IFlowFormValues,
	MEASUREMENT_QUANTITY_ID,
	SUPPLIER_CONSUMPTION_AMENDMENT_REASON_CODE,
} from "../../models/talos/mw4-flows";
import { useOnFirstTouch } from "../../form-generator";

interface IProps {
	formSubmitMutation: UseMutationResult<Boolean, Error, IFlowFormValues>;
	formData: IFlowFormValues;
	onFirstTouch?: () => void;
}

export const AddMW4FlowForm: React.FC<IProps> = ({
	formSubmitMutation,
	formData,
	onFirstTouch,
}) => {
	const handleOnSubmit = (values: IFlowFormValues) => {
		formSubmitMutation.mutate(values);
	};

	const handleTouched = useOnFirstTouch<IFlowFormValues>(onFirstTouch);

	return (
		<Formik
			enableReinitialize
			onSubmit={handleOnSubmit}
			initialValues={formData}
			validationSchema={formValidationSchema}
			validateOnChange={true}
		>
			{(form) => {
				handleTouched(form);

				return (
					<Form>
						<Box className="form-wrapper">
							<Box className="form-column">
								<TalosTextBox fieldName="mpan" label="Mpan*" form={form} />
								<TalosDropDown
									fieldName="measurementQuantityId"
									label="Measurement Quantity Id*"
									form={form}
									menuItems={MEASUREMENT_QUANTITY_ID.map((wl, idx) => (
										<MenuItem
											key={`measurement_quantity_id_${idx}`}
											value={wl.value}
										>
											{wl.display}
										</MenuItem>
									))}
								/>
								<TalosTextBox
									fieldName="consumptionAmendmentSupplierReference"
									label="Consumption Amendment Supplier Reference"
									form={form}
								/>
								<TalosDateField
									fieldName="consumptionAmendmentPeriodStartDate"
									label="Consumption Amendment Period Start Date*"
									form={form}
								/>
								<TalosDateField
									fieldName="consumptionAmendmentPeriodEndDate"
									label="Consumption Amendment Period End Date*"
									form={form}
								/>
								<TalosTextBox
									fieldName="grossRevisedConsumption"
									label="Gross Revised Consumption*"
									form={form}
								/>
								<TalosDropDown
									fieldName="supplierConsumptionAmendmentReasonCode"
									label="Supplier Consumption Amendment Reason Code*"
									form={form}
									menuItems={SUPPLIER_CONSUMPTION_AMENDMENT_REASON_CODE.map(
										(wl, idx) => (
											<MenuItem
												key={`supplier_consumption_amendment_reason_code_${idx}`}
												value={wl.value}
											>
												{wl.display}
											</MenuItem>
										)
									)}
								/>
								<TalosButton
									fieldName="form_submit"
									form={form}
									buttonText="Submit"
									loading={formSubmitMutation.isLoading}
								/>
							</Box>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};
