import React from "react";

import { Box, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";

import {
	TalosButton,
	TalosDateField,
	TalosDropDown,
	TalosTextBox,
} from "../forms";
import { formValidationSchema } from "./add-d0052-flow-form.validation-schema";
import { ID0052FlowFormValues } from "../../models/talos/d0052-flows";
import { GSP_DROPDOWN, PROFILE_CLASS } from "./d0052-flows-table";
import { useOnFirstTouch } from "../../form-generator";

interface IProps {
	formSubmitMutation: UseMutationResult<Boolean, Error, ID0052FlowFormValues>;
	formData: ID0052FlowFormValues;
	onFirstTouch?: () => void;
	submitDisabled?: boolean;
}

export const AddD0052FlowForm: React.FC<IProps> = ({
	formSubmitMutation,
	formData,
	onFirstTouch,
	submitDisabled,
}) => {
	const handleOnSubmit = (values: ID0052FlowFormValues) => {
		formSubmitMutation.mutate(values);
	};

	const handleTouched = useOnFirstTouch<ID0052FlowFormValues>(onFirstTouch);

	return (
		<Formik<ID0052FlowFormValues>
			enableReinitialize
			onSubmit={handleOnSubmit}
			initialValues={formData}
			validationSchema={formValidationSchema}
			validateOnChange={true}
		>
			{(form) => {
				handleTouched(form);

				return (
					<Form>
						<Box className="form-wrapper">
							<Box className="form-column">
								<TalosTextBox fieldName="mpan" label="MPAN*" form={form} />
								<TalosDateField
									fieldName="mpan_efd"
									label="MPAN EFD*"
									form={form}
								/>
								<TalosDropDown
									fieldName="profile_class"
									label="Profile Class*"
									form={form}
									menuItems={PROFILE_CLASS.map((pc, idx) => (
										<MenuItem key={`pc_${idx}`} value={pc}>
											{pc}
										</MenuItem>
									))}
								/>
								<TalosDateField
									fieldName="profile_class_efd"
									label="Profile Class EFD*"
									form={form}
								/>
								<TalosTextBox fieldName="ssc" label="SSC*" form={form} />
								<TalosDateField
									fieldName="scon_date"
									label="SCON Date*"
									form={form}
								/>
								<TalosDropDown
									fieldName="gsp"
									label="GSP*"
									form={form}
									menuItems={GSP_DROPDOWN.map((gsp, idx) => (
										<MenuItem key={`gsp_${idx}`} value={gsp}>
											{gsp}
										</MenuItem>
									))}
								/>
								<TalosTextBox
									fieldName="day_tpr"
									label="Anytime/Day TPR*"
									form={form}
								/>
								<TalosTextBox
									fieldName="day_eac"
									label="Anytime/Day EAC*"
									form={form}
								/>
								<TalosDateField
									fieldName="day_eac_efd"
									label="Anytime/Day EAC EFD*"
									form={form}
								/>
								<TalosTextBox
									fieldName="night_tpr"
									label="Night TPR"
									form={form}
								/>
								<TalosTextBox
									fieldName="night_eac"
									label="Night EAC"
									form={form}
								/>
								<TalosDateField
									fieldName="night_eac_efd"
									label="Night EAC EFD"
									form={form}
								/>
								<TalosButton
									fieldName="form_submit"
									form={form}
									buttonText="Submit"
									loading={formSubmitMutation.isLoading}
									disabled={submitDisabled}
								/>
							</Box>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};
