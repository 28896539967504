import * as Yup from "yup";

import {
	dateIsNotFuture,
	dateIsNotValid,
	MAX_REG_ID,
	maxLengthValidationMessage,
	POSITIVE_INTEGERS_ONLY_MSG,
	POSITIVE_NUMBERS_ONLY_ONE_DP_MSG,
	usefulRegex,
} from "../../utilities";

export const formValidationSchema = Yup.object().shape({
	mpan: Yup.string()
		.matches(usefulRegex.MPAN, "MPAN Must be 13 integers")
		.required("MPAN is required"),
	msn: Yup.string()
		.matches(
			usefulRegex.ELEC_MSN,
			"MSN must be between 3 and 12 alphanumeric characters"
		)
		.required("MSN is required"),
	readDate: Yup.string()
		.required("Read Date is required")
		.test(
			"read-date-is-not-future",
			"Read Date shouldn't be in the future",
			dateIsNotFuture
		)
		.test(
			"read-date-is-not-valid",
			"Read date should be a valid date",
			dateIsNotValid
		),
	readType: Yup.string().required("Read Type is required"),
	firstRegisterId: Yup.string()
		.matches(
			usefulRegex.REGISTER_ID,
			"First Register ID must be between 1 and 4 alphanumeric characters"
		)
		.required("First Register ID is required"),
	subRegisters: Yup.string().required(),
	secondRegisterId: Yup.string().when("subRegisters", {
		is: (value: string) => value === "Y",
		then: (schema) =>
			schema
				.matches(
					usefulRegex.REGISTER_ID,
					"Second Register ID must be between 1 and 4 alphanumeric characters"
				)
				.required("Second Register ID is required")
				.max(
					MAX_REG_ID,
					maxLengthValidationMessage(MAX_REG_ID, "Second Register ID")
				),
	}),
	firstRegisterIncorrectRead: Yup.number()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.required("First Incorrect Read is required")
		.test(
			"frir-1-dp",
			POSITIVE_NUMBERS_ONLY_ONE_DP_MSG,
			(value: number | null) =>
				value
					? usefulRegex.POSITIVE_NUMBERS_UP_TO_ONE_DECIMAL_PLACE_ONLY.test(
							value.toString()
					  )
					: true
		),
	secondRegisterIncorrectRead: Yup.number()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.when("subRegisters", {
			is: (value: string) => value === "Y",
			then: (schema) =>
				schema
					.required("Second Incorrect Read is required for two rate meters")
					.test(
						"srir-1-dp",
						POSITIVE_NUMBERS_ONLY_ONE_DP_MSG,
						(value: number | "") =>
							value
								? usefulRegex.POSITIVE_NUMBERS_UP_TO_ONE_DECIMAL_PLACE_ONLY.test(
										value.toString()
								  )
								: true
					),
		}),
	firstRegisterCorrectRead: Yup.number()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.required("First Correct Read is required")
		.test("srir-1-dp", POSITIVE_INTEGERS_ONLY_MSG, (value: number | "") =>
			value ? usefulRegex.POSITIVE_INTEGERS_ONLY.test(value.toString()) : true
		),
	secondRegisterCorrectRead: Yup.number()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.when("subRegisters", {
			is: (value: string) => value === "Y",
			then: (schema) =>
				schema
					.required("Second Correct Read is required for two rate meters")
					.test("srcr-1-dp", POSITIVE_INTEGERS_ONLY_MSG, (value: number | "") =>
						value
							? usefulRegex.POSITIVE_INTEGERS_ONLY.test(value.toString())
							: true
					),
		}),
});
