export enum RequestType {
	ElectricityOnly,
	GasOnly,
	Dual,
}

export enum BookingType {
	GAS = 0,
	ELEC_SINGLE = 1,
	ELEC_MULTI = 2,
}

export const GAS_JOB_TYPES = [
	"Ad Hoc Read Request",
	"Burns Test",
	"Photo Job",
	"Regular Read Appointment",
	"2 Hour Appointment",
] as const;

export const ELEC_JOB_TYPES = [
	"Ad Hoc Read Request",
	"Regular Read Appointment",
	"2 Hour Appointment",
	"Photo Job",
	"Kettle Test",
	"Load test (spaced test)",
] as const;

export const DUAL_JOB_TYPES = [
	"Dual Ad Hoc Read Request",
	"Dual Regular Read Appointment",
	"2 Hour Appointment Dual",
	"Dual Photo Job",
] as const;

export const JOB_CODES = [
	"ADGJ",
	"ADHS",
	"APDJ",
	"APPD",
	"APAJ",
	"APPA",
	"APEJ",
	"APPE",
	"APPJ",
	"APPP",
	"APTJ",
	"APPT",
	"SPAR",
	"SPAJ",
	"BURN",
	"SPER",
	"SPEJ",
	"KETT",
	"SPCE",
] as const;

const APPOINTMENT_TIME_CODES = [
	"AM",
	"PM",
	"EVE",
	"All Day",
	"08:00 - 10:00",
	"10:00 - 12:00",
	"12:00 - 14:00",
	"14:00 - 16:00",
	"16:00 - 18:00",
	"18:00 - 20:00",
] as const;

export type GasJob = (typeof GAS_JOB_TYPES)[number];
export type ElecJob = (typeof ELEC_JOB_TYPES)[number];
export type DualJob = (typeof DUAL_JOB_TYPES)[number];
export type JobCodes = (typeof JOB_CODES)[number];
export type AppointmentTimeCodes = (typeof APPOINTMENT_TIME_CODES)[number];

export interface IBookingFormValues {
	requestType: RequestType;
	mpan: string;
	mprn: string;
	elec_msn: string;
	gas_msn: string;
	customerName: string;
	houseNumber: string;
	houseName: string;
	postOutcode: string;
	postIncode: string;
	address: string;
	accessInstructions: string;
	elec_meterLocation: string;
	gas_meterLocation: string;
	specialInstructions: string;
	customerCareDetails: string;
	customerPassword: string;
	gas_registerID: string;
	gas_registerName: string;
	elec_registerID: string;
	elec_registerName: string;
	elec_secondRegisterID: string;
	elec_secondRegisterName: string;
	jobType: GasJob | ElecJob | DualJob | "";
	appointmentDate?: string;
	appointmentTime: AppointmentTimeCodes | "";
	earliestReadDate?: string;
	latestReadDate?: string;
	subRegisters: "Y" | "N";
}

export interface GasMeterData {
	mprn: string;
	msn: string;
	meterLocation: string;
	registerId: string;
	registerName: string;
}

export interface ElectricityMeterData {
	mpan: string;
	msn: string;
	meterLocation: string;
	registerId: string;
	registerName: string;
	secondRegisterId: string;
	secondRegisterName: string;
}

export interface BookingRequest {
	jobCode: JobCodes;
	customerName: string;
	houseNameNumber: string;
	address: string;
	postOutcode: string;
	postIncode: string;
	appointmentDate?: string;
	appointmentTime?: string;
	earliestReadDate?: string;
	latestReadDate?: string;
	accessInstructions?: string;
	specialInstructions?: string;
	customerCareDetails?: string;
	customerPassword?: string;
	electricityMeterData?: ElectricityMeterData;
	gasMeterData?: GasMeterData;
	agentName?: string;
	agentEmail?: string;
	sourceProcess?: string;
}

export interface BookingRequestResponse {
	id: string;
	bookingRequest: BookingRequest;
	dateRequestGenerated: string;
	dateRequestReceived?: string;
	status: "SUCCESS" | "FAILED" | "PENDING" | "CANCELLED";
	errors?: string[];
}
