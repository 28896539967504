import React from "react";

import { Box } from "@mui/material";
import { Form, Formik } from "formik";

import { TalosDatePicker, TalosButton } from "../forms";
import { validationSchema } from "./mpxn-by-date-validation-schema";
import { TalosMpanField } from "../forms/talos-mpan-field";

interface IProps {
	handleSubmit: (filter: IMpxnByDateFilterProps) => void;
	initialValues: IMpxnByDateFilterProps;
	filterType: "MPXN" | "MPAN" | "MPRN";
}

export interface IMpxnByDateFilterProps {
	mpxn?: string;
	start?: Date;
	end?: Date;
}

// MPXN is the parent term for both MPAN and MPRN
export const MpxnByDateFilter: React.FC<IProps> = (props: IProps) => {
	return (
		<Formik<IMpxnByDateFilterProps>
			initialValues={props.initialValues}
			onSubmit={(values) => {
				props.handleSubmit(values);
			}}
			validationSchema={validationSchema(props.filterType)}
		>
			{(form) => (
				<Form>
					<Box className="form-wrapper-horizontal">
						<Box className="form-column-narrow">
							<TalosMpanField
								fieldName="mpxn"
								label={props.filterType}
								form={form}
							/>
						</Box>
						<Box className="form-column-narrow">
							<TalosDatePicker
								fieldName="start"
								label="Start Date"
								form={form}
							/>
						</Box>
						<Box className="form-column-narrow">
							<TalosDatePicker fieldName="end" label="End Date" form={form} />
						</Box>
						<Box className="form-column-narrow">
							<TalosButton
								fieldName="search_button"
								buttonText="Search"
								form={form}
								loading={false}
								enableIfNotDirty={true}
							/>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};
