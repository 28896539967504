import { useMemo, useState } from "react";

import { getChangeReadRequests, processFilterParams } from "../../api/talos";
import { ChangeReadsTable } from "../../components";
import { ChangeReadResponse } from "../../models/talos";
import { PagedRequestsPage } from "../requests-list-page";
import {
	IMpxnByDateFilterProps,
	MpxnByDateFilter,
} from "../../components/filters/mpxn-by-date-filter";

export const ChangeReads = () => {
	const [filterState, setFilterState] = useState<IMpxnByDateFilterProps>({});
	const { mpxn, start, end } = filterState;
	const searchParams = useMemo(
		() =>
			processFilterParams({
				fields: { mpan: mpxn, unsentOnly: false },
				dates: { startDate: start, endDate: end },
			}),
		[mpxn, start, end]
	);

	return (
		<PagedRequestsPage<ChangeReadResponse>
			title="Change Read Requests"
			requestsName={"Change Read Requests"}
			requestsKey={"change-reads"}
			getRequests={getChangeReadRequests}
			RequestsTable={ChangeReadsTable}
			additionLink={"/add-change-read-request"}
			getRequestsOptions={searchParams}
			requestsOptions={
				<MpxnByDateFilter
					handleSubmit={setFilterState}
					initialValues={filterState}
					filterType="MPAN"
				/>
			}
		/>
	);
};
