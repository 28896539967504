import { format } from "date-fns";

import { getDaysBetweenDates } from "./read-calculations.helpers";
import bankHolidays from "../services/bank-holidays";

export const MAX_REG_ID = 10;
export const POSITIVE_NUMBERS_ONLY_MSG = "Positive numbers only";
export const POSITIVE_INTEGERS_ONLY_MSG = "Positive whole numbers only";
export const POSITIVE_NUMBERS_ONLY_ONE_DP_MSG =
	"Positive numbers up to one decimal place only";
export const ONLY_ONE_ZERO_MESSAGE = "Strings of 0 are not valid";

export const isBankHoliday = (date: Date): boolean => {
	const formattedDate = format(date, "yyyy-MM-dd");
	return bankHolidays.includes(formattedDate);
};

export const maxLengthValidationMessage = (length: number, field: string) => {
	return `${field} allows a maximum of ${length} characters.`;
};

export const isAStringOfZeros = (input: string) => {
	return input.startsWith("00") || (input.length > 1 && Number(input) === 0);
};

export const isDateAtLeastXDaysInTheFuture = (
	date: Date,
	daysInFuture: number
) => {
	const diff = Math.round(getDaysBetweenDates(new Date(), date));
	return diff > 0 && diff >= daysInFuture;
};

export const FUTURE = (input: number) => ++input;
export const PAST = (input: number) => --input;

export const XWorkdaysFromDate = (
	date: Date,
	workdaysApart: number,
	adjustment: typeof FUTURE | typeof PAST = FUTURE
) => {
	const targetDate = new Date(date);

	let adjustedWorkdays = 0;
	while (adjustedWorkdays < workdaysApart) {
		targetDate.setDate(adjustment(targetDate.getDate()));
		if (!isHoliday(targetDate)) {
			adjustedWorkdays++;
		}
	}
	return targetDate;
};

export const areDatesAtLeastXWorkdaysApart = (
	startDate: Date,
	endDate: Date,
	workdaysApart: number
) => {
	const targetDate = XWorkdaysFromDate(
		startDate,
		workdaysApart,
		startDate >= endDate ? PAST : FUTURE
	);
	const endDateOnly = endDate.setHours(0, 0, 0, 0);
	const targetDateOnly = targetDate.setHours(0, 0, 0, 0);
	return startDate >= endDate
		? targetDateOnly - endDateOnly > 0
		: endDateOnly - targetDateOnly > 0;
};

export const isDateAtLeastXWorkdaysInTheFuture = (
	date: Date,
	workdaysInFuture: number
) => {
	const currentDate = new Date();
	return (
		date > currentDate &&
		areDatesAtLeastXWorkdaysApart(currentDate, date, workdaysInFuture)
	);
};

export const dateIsNotFuture = (value: string) => {
	const date = new Date(value);
	return !(date && date > new Date());
};

export const dateFormat = "yyyy-MM-dd";

export const dateIsNotValid = (value: string) => {
	const date = new Date(value);
	return !isNaN(date.valueOf());
};

export const dateIsNotValidNullable = (value?: string | null) => {
	if (value === undefined || value === null) return true;

	const date = new Date(value);
	return !isNaN(date.valueOf());
};

export const TimeIsNotValid = (value: string) => {
	const time = new Date(value).toLocaleTimeString();
	return !isNaN(Number(time));
};

export const isHoliday = (date: Date | undefined) => {
	if (date == null) return false;

	const dayOfWeek = date.getDay();
	const formattedTargetDate = format(date, dateFormat);

	return (
		dayOfWeek === 0 ||
		dayOfWeek === 6 ||
		bankHolidays.includes(formattedTargetDate)
	);
};

export const isSunday = (date: Date | undefined) => {
	return date ? date.getDay() === 0 : false;
};

// The Outcode part is always first and consits of 2 to 4 chars
// https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom
export const getPostcodeOutcode = (postcode?: string) => {
	return postcode?.slice(0, -3);
};

// The Incode part is always at the end and consits of 3 chars
// https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom
export const getPostcodeIncode = (postcode?: string) => {
	return postcode?.slice(-3);
};
