import React, { useContext, useState } from "react";

import { Box, Divider, Typography, useTheme } from "@mui/material";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { FormikValues } from "formik";
import { AxiosError } from "axios";

import { useAuth } from "../auth";
import {
	AlertContext,
	handleError,
	ITalosAlert,
	useLazyTrackFormCompletion,
} from "../utilities";
import {
	AdditionPageProps,
	hasErrorHandler,
	hasSuccessHandler,
	ListPageOptions,
} from "../form-generator";

const scrollTo = (x: number, y: number) => {
	window.scrollTo({
		top: y,
		left: x,
		behavior: "smooth",
	});
};

const defaultSuccessHandler =
	<T,>(
		navigate: NavigateFunction,
		setTalosAlert: React.Dispatch<React.SetStateAction<ITalosAlert>>,
		instanceDescription: string,
		successMessage?: string | ((response: T) => string),
		listPage?: ListPageOptions
	) =>
	(response: T) => {
		onSuccessAlert(
			successMessage,
			response,
			instanceDescription,
			setTalosAlert
		);
		onSuccessNavigation(navigate, listPage?.link);
	};

const defaultErrorHandler =
	(
		setTalosAlert: React.Dispatch<React.SetStateAction<ITalosAlert>>,
		instanceDescription: string,
		errorMessage?: string | ((errorId: string) => string)
	) =>
	(e: AxiosError) => {
		if (e.response?.status === 400 && typeof e.response.data == "string") {
			setTalosAlert({
				message: `Validation failed: ${e.response.data}`,
				severity: "error",
			});
			return;
		}

		const errorId = handleError(e);

		setTalosAlert({
			message:
				errorMessage instanceof Function
					? errorMessage?.(errorId)
					: errorMessage ??
					  `Something went wrong submitting ${instanceDescription} form. Please contact IOps Support, Ticket ID: ${errorId}`,
			severity: "error",
		});

		// Scroll to the top of the page, so the user can see error message
		scrollTo(0, 0);
	};

const onSuccessNavigation = (
	navigate: NavigateFunction,
	listPageLink?: string
) => {
	// If an on success navigation link has been supplied then navigate
	// to it, otherwise use the list page link, if specified.
	if (listPageLink) {
		navigate(listPageLink);
	}
};

function onSuccessAlert<TApiResponse>(
	text: string | ((response: TApiResponse) => string) | undefined,
	response: TApiResponse,
	instanceDescription: string,
	setTalosAlert: (
		value: ((prevState: ITalosAlert) => ITalosAlert) | ITalosAlert
	) => void
) {
	const alertText =
		(text instanceof Function ? text(response) : text) ??
		`${instanceDescription} has been sent successfully`;

	setTalosAlert({
		message: alertText,
		severity: "success",
	});
}

/**
 * A component for adding a new flow or request and performing an API request
 * to persist it to the server.
 * @template TApiRequest The type of the request to send to the flow submission API.
 * @template TFormValues The type of the form values.
 * @template TApiResponse The type of the response from the flow submission API.
 * This may not be the actual response returned by the server but a transformed
 * value, depending upon the output of the request function used.
 */
export const AdditionPage = <
	TApiRequest extends unknown,
	TFormValues extends FormikValues,
	TApiResponse = Boolean
>(
	props: AdditionPageProps<TApiRequest, TFormValues, TApiResponse>
) => {
	const {
		additionFormKey,
		header,
		summary,
		underlyingFlowName,
		addendum,
		mapFormToValues,
		addInstanceRequest,
		AddInstanceForm,
		initialValues,
		listPage,
		disableAnalytics = false,
	} = props;

	const theme = useTheme();
	const authContext = useAuth();

	const { setTalosAlert } = useContext(AlertContext);

	const navigate = useNavigate();

	const [formData] = useState<TFormValues>(
		initialValues instanceof Function ? initialValues() : initialValues
	);

	const [triggerEventTimer, completeEventTimer] = useLazyTrackFormCompletion(
		additionFormKey,
		undefined,
		disableAnalytics
	);

	const additionInstanceName = props.additionInstanceName ?? additionFormKey;

	const formSubmitMutation = useMutation(
		(values: TFormValues) => {
			// Mutation will be invoked when the form is submitted, so complete the
			// form fill event timer at this point.
			completeEventTimer();

			const requestValues = mapFormToValues(values, authContext);
			return addInstanceRequest(authContext, requestValues);
		},
		{
			onSuccess: hasSuccessHandler(props)
				? props.onSuccess
				: defaultSuccessHandler(
						navigate,
						setTalosAlert,
						additionInstanceName,
						props.successMessage,
						listPage
				  ),
			onError: hasErrorHandler(props)
				? props.onError
				: defaultErrorHandler(
						setTalosAlert,
						additionInstanceName,
						props.errorMessage
				  ),
			onSettled() {
				// Scroll to the top of the page, so the user can see messages
				scrollTo(0, 0);
			},
		}
	);

	return (
		<Box
			sx={{ width: "700px", margin: "0 auto;" }}
			data-cy={"add-request-panel"}
		>
			<Box>
				<Typography
					variant="h1"
					sx={{ margin: theme.spacing(3, 0), textAlign: "center" }}
				>
					{header ? header : `New ${additionInstanceName} Request`}
				</Typography>
				<Typography variant="body1" data-cy="add-request-description">
					{summary ??
						[
							`Fill in this form to send a new ${additionInstanceName} `,
							underlyingFlowName ? `(${underlyingFlowName} Flow) ` : "",
							"request.",
						].join("")}
				</Typography>
				{addendum && (
					<Typography variant="body1" data-cy="add-request-addendum">
						{addendum}
					</Typography>
				)}
				<Typography variant="body1">* = Required</Typography>
				{listPage && (
					<Typography variant="body1">
						To see {listPage.subject} go to the{" "}
						<Link to={listPage.link}>{listPage.title}</Link> page
					</Typography>
				)}
			</Box>
			<Divider sx={{ margin: theme.spacing(3, 0) }} />
			<AddInstanceForm
				formSubmitMutation={formSubmitMutation}
				formData={formData}
				onFirstTouch={triggerEventTimer}
			/>
		</Box>
	);
};
