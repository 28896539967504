import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
	Button,
	Menu,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	useTheme,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";

import { ChangeReadResponse } from "../../models/talos";
import {
	AlertContext,
	ROLES,
	formatJavaStyleDateString,
	handleError,
	mapChangeReadsStatuses,
} from "../../utilities";
import { cancelChangeReadRequests } from "../../api/talos";
import { AuthContext } from "../../auth";

interface IProps {
	rowData: ChangeReadResponse[];
}

export const ChangeReadsTable: React.FC<IProps> = (props: IProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selected, setSelected] = useState<string>();

	const authContext = useContext(AuthContext);
	const { setTalosAlert } = useContext(AlertContext);

	const queryClient = useQueryClient();

	const navigate = useNavigate();
	const theme = useTheme();

	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement>,
		id: string
	) => {
		setAnchorEl(event.currentTarget);
		setSelected(id);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setSelected(undefined);
	};

	function handleResubmit(id: string): void {
		handleClose();
		navigate(`/add-change-read-request?id=${id}`);
	}

	const changeReadsCancelMutation = useMutation(
		(id: string) => cancelChangeReadRequests(authContext, id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("change-reads");
				setTalosAlert({
					message: "Successfuly canceled Change Read Request",
					severity: "success",
				});
			},
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong canceling change read, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
		}
	);

	const handleCancel = (id: string) => {
		changeReadsCancelMutation.mutate(id);
		handleClose();
	};

	const getActions = (result: ChangeReadResponse) => {
		const showCancelButton =
			result.status === "PENDING" &&
			authContext.hasRole(ROLES.CHANGE_READ_CANCEL);

		const isCancelled = result.status === "CANCELLED";
		if (!showCancelButton && !isCancelled) {
			return null;
		}

		return (
			<>
				<Button
					id="basic-button"
					sx={{ padding: theme.spacing(0) }}
					onClick={(e) => handleClick(e, result.id)}
				>
					Actions
				</Button>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={Boolean(anchorEl) && result.id === selected}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
					PaperProps={{
						sx: {
							filter: "drop-shadow(0px 1px 5px rgba(0,0,0,0.06))",
							boxShadow: "1px 1px  #4c4c4c",
						},
					}}
				>
					{showCancelButton ? (
						<MenuItem onClick={() => handleCancel(result.id)}>Cancel</MenuItem>
					) : null}
					{isCancelled ? (
						<MenuItem onClick={() => handleResubmit(result.id)}>
							Resubmit
						</MenuItem>
					) : null}
				</Menu>
			</>
		);
	};

	return (
		<TableContainer
			component={Paper}
			sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(3) }}
		>
			<Table size="small" data-cy="change-reads-table">
				<TableHead>
					<TableRow sx={{ background: "#efefef" }}>
						<TableCell colSpan={7}></TableCell>
						<TableCell
							colSpan={3}
							sx={{ textAlign: "center", borderLeft: "1px solid grey" }}
						>
							First Register
						</TableCell>
						<TableCell
							colSpan={3}
							sx={{
								textAlign: "center",
								borderLeft: "1px solid grey",
								borderRight: "1px solid grey",
							}}
						>
							Second Register
						</TableCell>
						<TableCell></TableCell>
					</TableRow>
					<TableRow sx={{ background: "#efefef" }}>
						<TableCell rowSpan={2}>STATUS</TableCell>
						<TableCell>Agent Name</TableCell>
						<TableCell>Date Raised</TableCell>
						<TableCell>MPAN</TableCell>
						<TableCell>MSN</TableCell>
						<TableCell>Read Date</TableCell>
						<TableCell>Read Type</TableCell>
						<TableCell
							sx={{ borderLeft: "1px solid grey", textAlign: "center" }}
						>
							ID
						</TableCell>
						<TableCell>Incorrect</TableCell>
						<TableCell>Correct</TableCell>
						<TableCell
							sx={{ borderLeft: "1px solid grey", textAlign: "center" }}
						>
							ID
						</TableCell>
						<TableCell>Incorrect</TableCell>
						<TableCell sx={{ borderRight: "1px solid grey" }}>
							Correct
						</TableCell>
						<TableCell>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.rowData
						.sort(
							(a, b) =>
								new Date(
									formatJavaStyleDateString(b.dateTimeRequestReceived, true)
								).getTime() -
								new Date(
									formatJavaStyleDateString(a.dateTimeRequestReceived, true)
								).getTime()
						)
						.map((result) => (
							<TableRow key={result.id}>
								<TableCell>{mapChangeReadsStatuses(result.status)}</TableCell>
								<TableCell>{result.agentName}</TableCell>
								<TableCell>
									{formatJavaStyleDateString(
										result.dateTimeRequestReceived,
										true
									)}
								</TableCell>
								<TableCell>{result.changeRead.mpan}</TableCell>
								<TableCell>{result.changeRead.msn}</TableCell>
								<TableCell>
									{formatJavaStyleDateString(result.changeRead.readDate, false)}
								</TableCell>
								<TableCell align="center">
									{result.changeRead.readType}
								</TableCell>
								<TableCell align="center" sx={{ borderLeft: "1px solid grey" }}>
									{result.changeRead.firstRegisterId}
								</TableCell>
								<TableCell align="center">
									{result.changeRead.firstRegisterIncorrectRead}
								</TableCell>
								<TableCell align="center">
									{result.changeRead.firstRegisterCorrectRead}
								</TableCell>
								<TableCell align="center" sx={{ borderLeft: "1px solid grey" }}>
									{result.changeRead.secondRegisterId}
								</TableCell>
								<TableCell align="center">
									{result.changeRead.secondRegisterIncorrectRead}
								</TableCell>
								<TableCell
									align="center"
									sx={{ borderRight: "1px solid grey" }}
								>
									{result.changeRead.secondRegisterCorrectRead}
								</TableCell>
								<TableCell>{getActions(result)}</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
