import React from "react";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import {
	AddBookingRequest,
	AddChangeReadRequest,
	AddD0052Flow,
	AddD0205Flow,
	AddF09Flow,
	AddMW2Flow,
	AddSI2Flow,
	BookingRequests,
	ChangeReads,
	D0052Flows,
	D0205Flows,
	EcoesPlan,
	F09Flows,
	Home,
	Layout,
	MigrationUpload,
	MW2Flows,
	NotFound,
	ReadsCalculations,
	RegistrationDetails,
	Registrations,
	SI2Flows,
	SubmitMOP04,
} from "../pages";
import { ROLES } from "../utilities";
import { ProtectedPage } from "./protected-route";
import { AddUT003Flow } from "../pages/ut003/add-ut003-flow";
import { UT003Flows } from "../pages/ut003/ut003-flows";
import { MW4Flows } from "../pages/mw4/mw4-flows";
import { AddMW4Flow } from "../pages/mw4/add-mw4-flow";
import { S33Flows } from "../pages/s33/s33-flows";
import { AddS33Flow, S33Type } from "../pages/s33/add-s33-flow";
import { LegacyDataItem } from "../pages/metering/change-registration/update-legacy-data-item";
import { IHDStatusUpdate } from "../pages/metering/change-registration/update-ihd-status";
import { ConsentGranularityUpdate } from "../pages/metering/change-registration/update-consent-granularity";
import { RequestSupplyDeactivation } from "../pages/metering/change-registration/request-supply-deactivation";
import { RequestSupplyDisconnection } from "../pages/metering/change-registration/request-supply-disconnection";
import { UpdateEnergisationStatus } from "../pages/metering/change-registration/update-energisation-status";
import { RequestInstallRemoveMeter } from "../pages/metering/change-registration/request-install-remove-meter";
import { AddFVRRequest } from "../pages/fvr/add-fvr-request";
import { FVRRequests } from "../pages/fvr/fvr-requests";
import { AddDMEXRequest } from "../pages/dmex/add-dmex-request";
import { DMEXRequests } from "../pages/dmex/dmex-requests";

export const TalosRouter: React.FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					<Route
						path="/new-booking-request"
						element={
							<ProtectedPage role={ROLES.BOOKING_REQUEST_WRITE}>
								<AddBookingRequest />
							</ProtectedPage>
						}
					/>
					<Route
						path="/booking-requests"
						element={
							<ProtectedPage role={ROLES.BOOKING_REQUEST_READ}>
								<BookingRequests />
							</ProtectedPage>
						}
					/>
					<Route
						path="/change-reads"
						element={
							<ProtectedPage role={ROLES.CHANGE_READ_READ}>
								<ChangeReads />
							</ProtectedPage>
						}
					/>
					<Route
						path="/add-change-read-request"
						element={
							<ProtectedPage role={ROLES.CHANGE_READ_WRITE}>
								<AddChangeReadRequest />
							</ProtectedPage>
						}
					/>
					<Route
						path="/d0205-flows"
						element={
							<ProtectedPage role={ROLES.FLOWS_USER}>
								<D0205Flows />
							</ProtectedPage>
						}
					/>
					<Route
						path="/add-d0205-flow"
						element={
							<ProtectedPage role={ROLES.FLOWS_ADMIN}>
								<AddD0205Flow />
							</ProtectedPage>
						}
					/>
					<Route
						path="/d0052-flows"
						element={
							<ProtectedPage role={ROLES.FLOWS_USER}>
								<D0052Flows />
							</ProtectedPage>
						}
					/>
					<Route
						path="/add-d0052-flow"
						element={
							<ProtectedPage role={ROLES.FLOWS_ADMIN}>
								<AddD0052Flow />
							</ProtectedPage>
						}
					/>
					<Route
						path="/ut003-flows"
						element={
							<ProtectedPage role={ROLES.FLOWS_USER}>
								<UT003Flows />
							</ProtectedPage>
						}
					/>
					<Route
						path="/add-ut003-flow"
						element={
							<ProtectedPage role={ROLES.FLOWS_ADMIN}>
								<AddUT003Flow />
							</ProtectedPage>
						}
					/>
					<Route
						path="/mw2-flows"
						element={
							<ProtectedPage role={ROLES.FLOWS_USER}>
								<MW2Flows />
							</ProtectedPage>
						}
					/>
					<Route
						path="/add-mw2-flow"
						element={
							<ProtectedPage role={ROLES.FLOWS_ADMIN}>
								<AddMW2Flow />
							</ProtectedPage>
						}
					/>
					<Route
						path="/mw4-flows"
						element={
							<ProtectedPage role={ROLES.FLOWS_USER}>
								<MW4Flows />
							</ProtectedPage>
						}
					/>
					<Route
						path="/add-mw4-flow"
						element={
							<ProtectedPage role={ROLES.FLOWS_ADMIN}>
								<AddMW4Flow />
							</ProtectedPage>
						}
					/>
					<Route
						path="/s33-flows"
						element={
							<ProtectedPage role={ROLES.FLOWS_USER}>
								<S33Flows />
							</ProtectedPage>
						}
					/>
					<Route
						path="/add-s33-meter-reading"
						element={
							<ProtectedPage role={ROLES.FLOWS_ADMIN}>
								<AddS33Flow type={S33Type.METER_READING} />
							</ProtectedPage>
						}
					/>
					<Route
						path="/add-s33-read-override"
						element={
							<ProtectedPage role={ROLES.FLOWS_ADMIN}>
								<AddS33Flow type={S33Type.READ_OVERRIDE} />
							</ProtectedPage>
						}
					/>
					<Route
						path="/reads-calculations"
						element={
							<ProtectedPage role={ROLES.UTILITIES_ACCESS}>
								<ReadsCalculations />
							</ProtectedPage>
						}
					/>
					<Route
						path="export/migrations"
						element={
							<ProtectedPage role={ROLES.EXPORT_REGISTRATION_BULK_WRITE}>
								<MigrationUpload />
							</ProtectedPage>
						}
					/>
					<Route
						path="export/ecoes"
						element={
							<ProtectedPage role={ROLES.EXPORT_REGISTRATION_BULK_WRITE}>
								<EcoesPlan />
							</ProtectedPage>
						}
					/>
					<Route
						path="export/registrations"
						element={
							<ProtectedPage role={ROLES.EXPORT_REGISTRATION_READ}>
								<Registrations />
							</ProtectedPage>
						}
					/>
					<Route
						path="export/registrations/:registrationId"
						element={
							<ProtectedPage role={ROLES.EXPORT_REGISTRATION_READ}>
								<RegistrationDetails />
							</ProtectedPage>
						}
					/>
					<Route
						path="outbound/submit-mop04"
						element={
							<ProtectedPage role={ROLES.OUTBOUND_MTD_WRITE}>
								<SubmitMOP04 />
							</ProtectedPage>
						}
					/>
					<Route
						path="change-registration/legacy-data"
						element={
							<ProtectedPage role={ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE}>
								<LegacyDataItem />
							</ProtectedPage>
						}
					/>
					<Route
						path={"/f09-flows"}
						element={
							<ProtectedPage role={ROLES.FLOWS_USER}>
								<F09Flows />
							</ProtectedPage>
						}
					/>
					<Route
						path={"/add-f09-flow"}
						element={
							<ProtectedPage role={ROLES.FLOWS_ADMIN}>
								<AddF09Flow />
							</ProtectedPage>
						}
					/>
					<Route
						path={"/add-si2-flow"}
						element={
							<ProtectedPage role={ROLES.FLOWS_ADMIN}>
								<AddSI2Flow />
							</ProtectedPage>
						}
					/>
					<Route
						path={"/si2-flows"}
						element={
							<ProtectedPage role={ROLES.FLOWS_USER}>
								<SI2Flows />
							</ProtectedPage>
						}
					/>
					<Route
						path="change-registration/ihd-status"
						element={
							<ProtectedPage role={ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE}>
								<IHDStatusUpdate />
							</ProtectedPage>
						}
					/>
					<Route
						path="change-registration/consent-granularity"
						element={
							<ProtectedPage role={ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE}>
								<ConsentGranularityUpdate />
							</ProtectedPage>
						}
					/>
					<Route
						path="change-registration/request-supply-deactivation"
						element={
							<ProtectedPage role={ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE}>
								<RequestSupplyDeactivation />
							</ProtectedPage>
						}
					/>
					<Route
						path="change-registration/request-supply-disconnection"
						element={
							<ProtectedPage role={ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE}>
								<RequestSupplyDisconnection />
							</ProtectedPage>
						}
					/>
					<Route
						path="change-registration/update-energisation-status"
						element={
							<ProtectedPage role={ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE}>
								<UpdateEnergisationStatus />
							</ProtectedPage>
						}
					/>
					<Route
						path="change-registration/request-install-remove-meter"
						element={
							<ProtectedPage role={ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE}>
								<RequestInstallRemoveMeter />
							</ProtectedPage>
						}
					/>
					<Route
						path={"/add-force-validate-read"}
						element={
							<ProtectedPage role={ROLES.SETTLEMENTS_READS_FVR_WRITE}>
								<AddFVRRequest />
							</ProtectedPage>
						}
					/>
					<Route
						path={"/force-validate-read-requests"}
						element={
							<ProtectedPage role={ROLES.SETTLEMENTS_READS_FVR_READ}>
								<FVRRequests />
							</ProtectedPage>
						}
					/>
					<Route
						path={"/add-dmex"}
						element={
							<ProtectedPage role={ROLES.SETTLEMENTS_READS_DMEX_WRITE}>
								<AddDMEXRequest />
							</ProtectedPage>
						}
					/>
					<Route
						path={"/dmex-requests"}
						element={
							<ProtectedPage role={ROLES.SETTLEMENTS_READS_DMEX_READ}>
								<DMEXRequests />
							</ProtectedPage>
						}
					/>
					<Route path="404" element={<NotFound />} />
				</Route>
				<Route path="*" element={<Navigate to="/404" />} />
			</Routes>
		</BrowserRouter>
	);
};
