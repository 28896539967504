import { useEffect, useState } from "react";

import { FormikProps } from "formik";

/**
 * Hook which returns a function into which a formik form (FormikProps) object
 * may be passed to track first touch. This will fire when data is first entered
 * into any text/dropdown. This does not work for radios but should be sufficient
 * for most use cases.
 * @param onFirstTouch handler to invoke when form is first touched
 * @return Function which takes a form object to inspect for first touch.
 */
export const useOnFirstTouch = <TFormValues>(
	onFirstTouch: (() => void) | undefined
) => {
	const [touched, setTouched] = useState(false);

	useEffect(() => {
		if (!touched || !onFirstTouch) return;

		onFirstTouch();
	}, [touched, onFirstTouch]);

	return (form: FormikProps<TFormValues>) => {
		const initialTouchedKeys = Object.keys(form.initialTouched);
		const currentTouchedKeys = Object.keys(form.touched);
		const hasFreshlyTouchedKeys = currentTouchedKeys.some(
			(key) => !initialTouchedKeys.includes(key)
		);
		setTouched(hasFreshlyTouchedKeys);
	};
};
