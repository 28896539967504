import { Box, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";

import {
	TalosButton,
	TalosDropDown,
	TalosRadioGroup,
	TalosTextBox,
} from "../forms";
import { formValidationSchema } from "./add-d0205-flow-form.validation-schema";
import {
	ENERGISATION_STATUSES,
	IFlowFormValues,
	METER_TYPES,
} from "../../models/talos/d0205-flows";
import { useOnFirstTouch } from "../../form-generator";

interface IProps {
	formSubmitMutation: UseMutationResult<Boolean, Error, IFlowFormValues>;
	formData: IFlowFormValues;
	onFirstTouch?: () => void;
}

export const AddD0205FlowForm: React.FC<IProps> = ({
	formSubmitMutation,
	formData,
	onFirstTouch,
}) => {
	const handleOnSubmit = (values: IFlowFormValues) => {
		formSubmitMutation.mutate(values);
	};

	const handleTouched = useOnFirstTouch<IFlowFormValues>(onFirstTouch);

	return (
		<Formik
			enableReinitialize
			onSubmit={handleOnSubmit}
			initialValues={formData}
			validationSchema={formValidationSchema}
			validateOnChange={true}
		>
			{(form) => {
				handleTouched(form);

				return (
					<Form>
						<Box className="form-wrapper">
							<Box className="form-column">
								<TalosTextBox fieldName="mpan" label="MPAN*" form={form} />
								<TalosDropDown
									fieldName="energisation_status"
									label="Energisation Status*"
									form={form}
									menuItems={ENERGISATION_STATUSES.map((wl, idx) => (
										<MenuItem
											key={`energisation_status_${idx}`}
											value={wl.value}
										>
											{wl.display}
										</MenuItem>
									))}
								/>
								<TalosTextBox
									fieldName="profile_class_id"
									label="Profile class Id*"
									form={form}
								/>
								<TalosTextBox fieldName="ssc" label="SSC*" form={form} />
								<TalosDropDown
									fieldName="meter_type"
									label="Meter Type*"
									form={form}
									menuItems={METER_TYPES.map((wl, idx) => (
										<MenuItem key={`meter_type_${idx}`} value={wl.value}>
											{wl.display}
										</MenuItem>
									))}
								/>
								<h4>Do you want to update SSC?*</h4>
								<TalosRadioGroup
									form={form}
									fieldName="update_ssc"
									options={[
										{
											value: "Y",
											label: "Yes",
										},
										{
											value: "N",
											label: "No",
										},
									]}
								/>
								<TalosButton
									fieldName="form_submit"
									form={form}
									buttonText="Submit"
									loading={formSubmitMutation.isLoading}
								/>
							</Box>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};
