import { AxiosResponse } from "axios";
import { Field, Form, Formik } from "formik";
import {
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from "@mui/material";

import { AdditionFormProps } from "../../form-generator";
import { IUpdateLegacyDataItem, PCId } from "../../models/metering";
import { updateLegacyDataItemFormValidationSchema } from "../metering/change-of-registration-data";
import { TalosMpanField } from "../forms/talos-mpan-field";
import { ThemisDateField } from "../forms/themis-date-field";

export const UpdateLegacyDataItemForm = ({
	formSubmitMutation,
	formData,
}: AdditionFormProps<IUpdateLegacyDataItem, AxiosResponse>) => (
	<Formik
		onSubmit={(values: IUpdateLegacyDataItem, { resetForm }) => {
			const valuesToSubmit: IUpdateLegacyDataItem = {
				mpan: values.mpan,
				pcId: values.pcId || undefined,
				pcEffectiveFromSettlementDate:
					values.pcEffectiveFromSettlementDate || undefined,
				sscId: values.sscId || undefined,
				sscEffectiveFromSettlementDate:
					values.sscEffectiveFromSettlementDate || undefined,
			};
			return formSubmitMutation.mutate(valuesToSubmit, {
				onSuccess: () => resetForm(),
			});
		}}
		initialValues={formData}
		validationSchema={updateLegacyDataItemFormValidationSchema}
		validateOnChange
		enableReinitialize
	>
		{(form) => (
			<Form>
				<Stack gap={2}>
					<TalosMpanField fieldName="mpan" label="MPAN*" form={form} />
					<FormControl>
						<InputLabel id={"pc_id_label"} error={!!form.errors.pcId}>
							{"PC Id"}
						</InputLabel>
						<Field
							name="pcId"
							label="PC Id"
							labelId={"pc_id_label"}
							as={Select}
							form={form}
							error={!!form.errors.pcId}
						>
							{[["", "None"], ...Object.entries(PCId)].map((val, index) => (
								<MenuItem key={`pc_id_${index}`} value={val[0]}>
									{`${val[1]}${val[0] ? ` - ${val[0]}` : ""}`}
								</MenuItem>
							))}
						</Field>
						<FormHelperText error={!!form.errors.pcId}>
							{form.errors.pcId}
						</FormHelperText>
					</FormControl>
					<ThemisDateField
						value={form.values.pcEffectiveFromSettlementDate!!}
						name="pcEffectiveFromSettlementDate"
						label="PC Effective From Settlement Date"
						helperText={form.errors.pcEffectiveFromSettlementDate}
						error={!!form.errors.pcEffectiveFromSettlementDate}
						form={form}
					/>
					<Field
						name="sscId"
						label="SSC Id"
						as={TextField}
						form={form}
						onChange={(e: any) => {
							e.preventDefault();
							const { value } = e.target;
							const regex = /^\d{1,4}$/;
							if (regex.test(value)) {
								form.setFieldValue("sscId", value);
							} else if (value.length === 0) form.setFieldValue("sscId", "");
						}}
						error={!!form.errors.sscId}
						helperText={form.errors.sscId}
					/>
					<ThemisDateField
						value={form.values.sscEffectiveFromSettlementDate!!}
						name="sscEffectiveFromSettlementDate"
						label="SSC Effective From Settlement Date"
						helperText={form.errors.sscEffectiveFromSettlementDate}
						error={!!form.errors.sscEffectiveFromSettlementDate}
						form={form}
					/>
				</Stack>
				<Stack direction={"row"} gap={2} marginTop={2}>
					<Button
						variant="contained"
						type="submit"
						disabled={!form.isValid || formSubmitMutation.isLoading}
					>
						Submit
					</Button>
				</Stack>
			</Form>
		)}
	</Formik>
);
