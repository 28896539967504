import { useContext, useEffect } from "react";

import { FormikProps, FormikValues, getIn } from "formik";

import { getMpanPropertyInfo } from "../../api/cop";
import { AuthContext } from "../../auth";
import { MpanPropertyInfoResponse } from "../../models/talos/cop-mpan-property-info";
import { TalosMpanField } from "./talos-mpan-field";

type PropertiesWithNumericValues<T> = {
	[P in keyof T]: T[P] extends number ? P : never;
}[keyof T];

export const mapCopFields = (
	copValues: MpanPropertyInfoResponse | null,
	formValues: any,
	fieldsToMap: Record<string, string>,
	additionalFields?: Record<string, string | undefined>
) => {
	if (copValues == null) return formValues;

	let newValues = Object.fromEntries(
		Object.entries(fieldsToMap).map(([key, value]) => [
			key,
			getIn(copValues, value),
		])
	);

	return {
		...formValues,
		...newValues,
		...additionalFields,
	};
};

export const TalosAutofillMpanField = <
	TFormValues extends FormikValues = FormikValues
>({
	label,
	form,
	fieldName,
	disabled,
	autofillFields,
}: {
	label: string;
	form: FormikProps<TFormValues>;
	fieldName: PropertiesWithNumericValues<FormikValues>;
	disabled?: boolean;
	autofillFields: (
		response: MpanPropertyInfoResponse | null,
		form: FormikProps<TFormValues>
	) => void;
}) => {
	const authContext = useContext(AuthContext);

	useEffect(() => {
		let isFetching = false;
		let mpan = form.values[fieldName];

		if (mpan.toString().length == 13) {
			getMpanPropertyInfo(authContext, mpan.toString()).then((data) => {
				if (!isFetching && data != null) {
					autofillFields(data, form);
				}
			});
		}
		return () => {
			isFetching = true;
		};
	}, [form.values.mpan]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<TalosMpanField
			label={label}
			fieldName={fieldName}
			form={form}
			disabled={disabled}
		/>
	);
};
