import React from "react";

import { FormikProps, FormikValues } from "formik";
import { Box, MenuItem } from "@mui/material";

import {
	BooleanFieldDescription,
	DateFieldDescription,
	FieldDescription,
	FieldDescriptions,
	MpanFieldDescription,
	NumericFieldDescription,
	RadioFieldDescription,
	SectionDescription,
	SelectFieldDescription,
	StringFieldDescription,
} from "./form.types";
import {
	TalosDatePicker,
	TalosDropDown,
	TalosNumberBox,
	TalosRadioGroup,
	TalosTextBox,
} from "../components";
import { TalosMpanField } from "../components/forms/talos-mpan-field";
import {
	isBooleanField,
	isDateField,
	isMpanField,
	isNumericField,
	isRadioField,
	isSection,
	isSelectField,
	isStringField,
} from "./form.utilities";
import { notEmpty } from "../utilities/predicates";
import { usefulRegex } from "../utilities";
import { TalosAutofillMpanField } from "../components/forms/talos-autofill-mpan-field";

const labelFromDescription = (description: FieldDescription) =>
	description.required ? `${description.label}*` : description.label;
const dateComponent = <TFormValues extends FormikValues>(
	dateFieldDescription: DateFieldDescription
) =>
	function DateComponent({ form }: { form: FormikProps<TFormValues> }) {
		return (
			<TalosDatePicker
				fieldName={dateFieldDescription.fieldName}
				label={labelFromDescription(dateFieldDescription)}
				form={form}
				{...dateFieldDescription.datePickerProps}
			/>
		);
	};
const numericComponent = <TFormValues extends FormikValues>(
	numericFieldDescription: NumericFieldDescription
) =>
	function NumericComponent({ form }: { form: FormikProps<TFormValues> }) {
		return (
			<TalosNumberBox
				disabled={numericFieldDescription.disabled}
				fieldName={numericFieldDescription.fieldName}
				label={labelFromDescription(numericFieldDescription)}
				form={form}
			/>
		);
	};
const radioComponent = <TFormValues extends FormikValues>(
	radioFieldDescription: RadioFieldDescription
) =>
	function RadioComponent({ form }: { form: FormikProps<TFormValues> }) {
		return (
			<TalosRadioGroup
				label={labelFromDescription(radioFieldDescription)}
				fieldName={radioFieldDescription.fieldName}
				options={radioFieldDescription.options}
				form={form}
			/>
		);
	};
const selectComponent = <TFormValues extends FormikValues>(
	selectFieldDescription: SelectFieldDescription
) =>
	function SelectComponent({ form }: { form: FormikProps<TFormValues> }) {
		return (
			<TalosDropDown
				disabled={selectFieldDescription.disabled}
				fieldName={selectFieldDescription.fieldName}
				label={labelFromDescription(selectFieldDescription)}
				form={form}
				menuItems={selectFieldDescription.options.map((option, idx) => (
					<MenuItem
						key={`${selectFieldDescription.fieldName}_${idx}`}
						value={option.value}
					>
						{option.label}
					</MenuItem>
				))}
			/>
		);
	};
const stringComponent = <TFormValues extends FormikValues>(
	stringFieldDescription: StringFieldDescription
) => {
	const maxRows = stringFieldDescription.maxRows ?? 1;
	const minRows = stringFieldDescription.minRows ?? 1;
	if (maxRows < minRows || maxRows < 1 || minRows < 1) {
		throw Error("Invalid row count configuration specified");
	}

	return function StringComponent({
		form,
	}: {
		form: FormikProps<TFormValues>;
	}) {
		return (
			<TalosTextBox
				disabled={stringFieldDescription.disabled}
				fieldName={stringFieldDescription.fieldName}
				label={labelFromDescription(stringFieldDescription)}
				minRows={stringFieldDescription.minRows}
				maxRows={stringFieldDescription.maxRows}
				multiline={(stringFieldDescription.minRows ?? 1) > 1}
				form={form}
			/>
		);
	};
};
const booleanComponent = <TFormValues extends FormikValues>(
	fieldDescription: BooleanFieldDescription
) =>
	function BooleanComponent({ form }: { form: FormikProps<TFormValues> }) {
		return (
			<TalosRadioGroup
				label={labelFromDescription(fieldDescription)}
				fieldName={fieldDescription.fieldName}
				options={[
					{ label: "Yes", value: "1" },
					{ label: "No", value: "0" },
				]}
				form={form}
			/>
		);
	};
const sectionComponent = <TFormValues extends FormikValues>(
	sectionDescription: SectionDescription
) => {
	const fieldComponents = formComponentsFromFields<TFormValues>(
		sectionDescription.fields
	);
	return function SectionComponent({
		form,
	}: {
		form: FormikProps<TFormValues>;
	}) {
		// If the section is excluded then return an empty element
		return sectionDescription.excludeFromForm &&
			sectionDescription.excludeFromForm(form.values) ? (
			<></>
		) : (
			<Box className="form-column">
				{sectionDescription.title && <h3>{sectionDescription.title}</h3>}
				{fieldComponents.filter(notEmpty).map((Component, index) => (
					<Component form={form} key={`form-component-${index}`} />
				))}
			</Box>
		);
	};
};
const mpanComponent = <TFormValues extends FormikValues>(
	mpanFieldDescription: MpanFieldDescription
) =>
	function MpanComponent({ form }: { form: FormikProps<TFormValues> }) {
		return mpanFieldDescription.autofillFields ? (
			<TalosAutofillMpanField
				label={labelFromDescription(mpanFieldDescription)}
				form={form}
				fieldName={mpanFieldDescription.fieldName}
				autofillFields={mpanFieldDescription.autofillFields}
			/>
		) : (
			<TalosMpanField
				label={labelFromDescription(mpanFieldDescription)}
				form={form}
				fieldName={mpanFieldDescription.fieldName}
			/>
		);
	};
/**
 * Responsible for taking a description of a form, which is represented as an
 * array of FieldDescription or SectionDescription objects, and transforming it
 * into an array of React components. Each component in the resulting array
 * represents a form field based on the provided description.
 * @param fields Field or section descriptions to generate components for.
 * @returns An array of React components.
 */
export const formComponentsFromFields = <TFormValues extends FormikValues>(
	fields: FieldDescriptions | SectionDescription[]
) =>
	fields
		.map((fieldOrSection) => {
			// Unfortunately switch statements don't allow for smart casting so directly
			// checking the type of the fieldOrSection and providing switch statement
			// with `true` as the condition is the tidiest way currently available.
			// Support for this form was added in Typescript 5.3.
			switch (true) {
				case isMpanField(fieldOrSection):
					return mpanComponent<TFormValues>(fieldOrSection);
				case isBooleanField(fieldOrSection):
					return booleanComponent<TFormValues>(fieldOrSection);
				case isDateField(fieldOrSection):
					return dateComponent<TFormValues>(fieldOrSection);
				case isNumericField(fieldOrSection):
					return numericComponent<TFormValues>(fieldOrSection);
				case isRadioField(fieldOrSection):
					return radioComponent<TFormValues>(fieldOrSection);
				case isStringField(fieldOrSection):
					return stringComponent<TFormValues>(fieldOrSection);
				case isSelectField(fieldOrSection):
					return selectComponent<TFormValues>(fieldOrSection);
				case isSection(fieldOrSection):
					return sectionComponent<TFormValues>(fieldOrSection);
				default:
					throw Error("Unknown field type");
			}
		})
		.filter(notEmpty);

export const COMMON_FIELDS: Record<string, FieldDescriptions[number]> = {
	MPAN: {
		componentType: "mpan",
		label: "MPAN",
		fieldName: "mpan",
		required: true,
	},
	MSN: {
		componentType: "string",
		label: "MSN",
		fieldName: "msn",
		minLength: 3,
		maxLength: 10,
	},
	REGISTER_READ: {
		componentType: "numeric",
		label: "Read",
		fieldName: "read",
		min: 0,
		max: 9999999.9,
		customValidation: (schema) =>
			schema.test(
				"valid-reading",
				(fieldName) =>
					`${fieldName.path} must be less than or equal to 9999999.9`,
				(value) =>
					value ? usefulRegex.REGISTER_READING.test(value.toString()) : true
			),
	},
};
