import React from "react";

import * as Yup from "yup";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";

import { TalosButton, TalosDatePicker } from ".";
import { IDateFilterProps } from "../filters/search-by-date-filter";

const validationSchema = Yup.object({
	start: Yup.string().required(),
	end: Yup.string()
		.required()
		.when(["start"], (start) => {
			return Yup.date()
				.min(
					new Date(start[0] as string),
					"End date must be after the start date"
				)
				.required();
		}),
});

interface IProps {
	handleSubmit: (dateRange: IDateFilterProps) => void;
	loading: boolean;
	initialValues: { start: string; end: string };
}

export const TalosDateRangePicker: React.FC<IProps> = (props: IProps) => {
	const handleOnSubmit = (values: { start: string; end: string }) => {
		props.handleSubmit({
			start: new Date(values.start),
			end: new Date(values.end),
		});
	};

	return (
		<Formik
			initialValues={props.initialValues}
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
		>
			{(form) => (
				<Form>
					<Box className="form-wrapper-horizontal">
						<Box className="form-column-narrow">
							<TalosDatePicker
								fieldName="start"
								label="Start Date"
								form={form}
							/>
						</Box>
						<Box className="form-column-narrow">
							<TalosDatePicker fieldName="end" label="End Date" form={form} />
						</Box>
						<Box className="form-column-narrow">
							<TalosButton
								fieldName="button_search"
								buttonText="Search"
								form={form}
								loading={props.loading}
								enableIfNotDirty={true}
							/>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};
