export interface IUpdateLegacyDataItem {
	mpan: number | "";
	pcId?: string;
	pcEffectiveFromSettlementDate?: string;
	sscId?: string;
	sscEffectiveFromSettlementDate?: string;
}
export enum PCId {
	DOMESTIC_UNRESTRICTED = "1",
	DOMESTIC_ECONOMY_7 = "2",
	NON_DOMESTIC_UNRESTRICTED = "3",
	NON_DOMESTIC_ECONOMY_7 = "4",
	NON_DOMESTIC_MD_LOAD_FACTOR_0_20_PERCENT = "5",
	NON_DOMESTIC_MD_LOAD_FACTOR_20_30_PERCENT = "6",
	NON_DOMESTIC_MD_LOAD_FACTOR_30_40_PERCENT = "7",
	NON_DOMESTIC_MD_LOAD_FACTOR_40_PERCENT_PLUS = "8",
}
export const initialUpdateLegacyDataItemFormValues: IUpdateLegacyDataItem = {
	mpan: "",
	pcId: "",
	pcEffectiveFromSettlementDate: "",
	sscId: "",
	sscEffectiveFromSettlementDate: "",
};
