import { useMemo, useState } from "react";

import { getFVRRequests, getSettlementsSearchParams } from "../../api/talos";
import {
	FVRResponse,
	RegisterData,
} from "../../models/talos/force-validation-reads";
import { flowsTable } from "../../components/tables/flows-table";
import { PagedRequestsPage } from "../requests-list-page";
import {
	IMpxnByDateFilterProps,
	MpxnByDateFilter,
} from "../../components/filters/mpxn-by-date-filter";
import { isFailingReadsRequestStatus } from "../../utilities/settlements-reads.helpers";

const isResponseKey = (key: string): key is keyof FVRResponse =>
	[
		"status",
		"agentName",
		"mpan",
		"msn",
		"readDate",
		"readType",
		"createdAt",
		"reg1Data",
		"reg2Data",
	].includes(key);

const isRegDataKey = (key: string | undefined): key is keyof RegisterData =>
	key !== undefined && ["regId", "read"].includes(key);

const FVRTable = flowsTable<FVRResponse>({
	columns: [
		{ label: "STATUS", key: "status" },
		{ label: "Agent Name", key: "agentName" },
		{ label: "MPAN", key: "mpan" },
		{ label: "MSN", key: "msn" },
		{ label: "Read Date", key: "readDate" },
		{ label: "Read Type", key: "readType" },
		{ label: "Register 1 ID", key: "reg1Data.regId" },
		{ label: "Register 1 Read", key: "reg1Data.read" },
		{ label: "Register 2 ID", key: "reg2Data.regId" },
		{ label: "Register 2 Read", key: "reg2Data.read" },
		{ label: "Created At", key: "createdAt" },
	],
	dataKey: "fvr",

	getId: (response: FVRResponse) => response.id,
	getColumnContent: (columnKey, response) => {
		if (
			columnKey === "status" &&
			isFailingReadsRequestStatus(response.status)
		) {
			return "PENDING";
		}
		// Look up value based on where in the response type the column key exists.
		if (isResponseKey(columnKey)) return response[columnKey];

		const [regKey, regDataKey] = columnKey.split(".");

		if (regKey && regDataKey) {
			const regData = isResponseKey(regKey)
				? (response[regKey] as RegisterData)
				: undefined;
			const regDataValue = isRegDataKey(regDataKey)
				? regData?.[regDataKey]
				: undefined;

			return regDataValue;
		}

		throw Error("Unknown column key");
	},
});

export const FVRRequests = () => {
	const [filterState, setFilterState] = useState<IMpxnByDateFilterProps>({});
	const { mpxn, start, end } = filterState;
	const searchParams = useMemo(
		() => getSettlementsSearchParams(mpxn, start, end),
		[mpxn, start, end]
	);
	return (
		<PagedRequestsPage<FVRResponse>
			title="Force Validate Read"
			requestsName={"Force Validate Read"}
			requestsKey={"force-validate-read-requests"}
			getRequests={getFVRRequests}
			RequestsTable={FVRTable}
			requestsOptions={
				<MpxnByDateFilter
					handleSubmit={setFilterState}
					initialValues={filterState}
					filterType="MPAN"
				/>
			}
			additionLink={"/add-force-validate-read"}
			getRequestsOptions={searchParams}
		/>
	);
};
