import * as Yup from "yup";

import { dateIsNotValid } from "../../../utilities";

export const updateLegacyDataItemFormValidationSchema = Yup.object().shape(
	{
		mpan: Yup.number()
			.required("MPAN is required.")
			.min(1000000000000, "MPAN must be exactly 13 digits.")
			.max(9999999999999, "MPAN must be exactly 13 digits."),
		pcEffectiveFromSettlementDate: Yup.string()
			.when("pcId", {
				is: (value: string) => value !== undefined,
				then: (schema) =>
					schema
						.required("PC Effective From Settlement Date is required.")
						.test(
							"invalid-date-action",
							"PC Effective From Settlement Date should be a valid date.",
							dateIsNotValid
						),
				otherwise: (schema) => schema.notRequired(),
			})
			.when("sscEffectiveFromSettlementDate", {
				is: (value?: string) => value === undefined,
				then: (schema) =>
					schema
						.required("At least one of the date fields must be populated.")
						.test(
							"invalid-date-action",
							"PC Effective From Settlement Date should be a valid date.",
							dateIsNotValid
						),
				otherwise: (schema) => schema.notRequired(),
			}),
		sscId: Yup.string()
			.optional()
			.length(4, "SSC Id must be exactly 4 characters long."),
		sscEffectiveFromSettlementDate: Yup.string()
			.when("sscId", {
				is: (value: string) => value !== undefined,
				then: (schema) =>
					schema
						.required("SSC Effective From Settlement Date is required.")
						.test(
							"invalid-date-action",
							"SSC Effective From Settlement Date should be a valid date.",
							dateIsNotValid
						),
				otherwise: (schema) => schema.notRequired(),
			})
			.when("pcEffectiveFromSettlementDate", {
				is: (value?: string) => value === undefined,
				then: (schema) =>
					schema
						.required("At least one of the date fields must be populated.")
						.test(
							"invalid-date-action",
							"SSC Effective From Settlement Date should be a valid date.",
							dateIsNotValid
						),
				otherwise: (schema) => schema.notRequired(),
			}),
	},
	[["sscEffectiveFromSettlementDate", "pcEffectiveFromSettlementDate"]]
);
