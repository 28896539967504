import { Box, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";

import {
	TalosButton,
	TalosDateField,
	TalosDropDown,
	TalosTextBox,
	TalosValidationWarning,
} from "../forms";
import {
	formValidationSchema,
	longSNACValidationMessage,
} from "./add-mw2-flow-form.validation-schema";
import { IFlowFormValues, SUPPLIER_TYPES } from "../../models/talos/mw2-flows";
import { WarningType } from "../forms/talos-validation-warning";
import {
	longMSNValidationMessage,
	shortMSNValidationMessage,
} from "../../utilities/mhhs-validation-messages";
import { useOnFirstTouch } from "../../form-generator";

interface IProps {
	formSubmitMutation: UseMutationResult<Boolean, Error, IFlowFormValues>;
	formData: IFlowFormValues;
	onFirstTouch?: () => void;
}

export const AddMW2FlowForm: React.FC<IProps> = ({
	formSubmitMutation,
	formData,
	onFirstTouch,
}) => {
	const handleOnSubmit = (values: IFlowFormValues) => {
		formSubmitMutation.mutate(values);
	};

	const handleTouched = useOnFirstTouch<IFlowFormValues>(onFirstTouch);

	return (
		<Formik
			enableReinitialize
			onSubmit={handleOnSubmit}
			initialValues={formData}
			validationSchema={formValidationSchema}
			validateOnChange={true}
		>
			{(form) => {
				handleTouched(form);

				return (
					<Form>
						<Box className="form-wrapper">
							<Box className="form-column">
								<TalosTextBox fieldName="mpan" label="Mpan*" form={form} />
								<TalosDropDown
									fieldName="supplierNotificationType"
									label="Supplier Notification Type*"
									form={form}
									menuItems={SUPPLIER_TYPES.map((wl, idx) => (
										<MenuItem
											key={`supplier_notification_type_${idx}`}
											value={wl.value}
										>
											{wl.display}
										</MenuItem>
									))}
								/>
								<TalosDateField
									fieldName="supplierNotificationEffectiveFromDate"
									label="Supplier Notification EFD*"
									form={form}
								/>
								<TalosDateField
									fieldName="supplierNotificationExpiryDate"
									label="Supplier Notification Expiry Date"
									form={form}
								/>
								{(form.values.supplierNotificationType === "SNRD" ||
									form.values.supplierNotificationType === "SNRE") && (
									<>
										<TalosTextBox
											fieldName="meterIdSerialNumber"
											label="Meter Id Serial Number*"
											form={form}
										/>
										<TalosValidationWarning
											field={form.values.meterIdSerialNumber}
											message={shortMSNValidationMessage}
											type={WarningType.BETWEEN}
											rangeMin={3}
											rangeMax={9}
										/>
										<TalosValidationWarning
											field={form.values.meterIdSerialNumber}
											message={longMSNValidationMessage}
											type={WarningType.BETWEEN}
											rangeMin={15}
											rangeMax={18}
										/>
									</>
								)}
								<TalosTextBox
									fieldName="additionalInformation"
									label="Additional Information"
									form={form}
								/>
								{form.values.supplierNotificationType === "SNAC" && (
									<>
										<TalosTextBox
											fieldName="supplierNominatedAnnualConsumption"
											label="Supplier Nominated Annual Consumption*"
											form={form}
										/>
										<TalosValidationWarning
											field={form.values.supplierNominatedAnnualConsumption}
											message={longSNACValidationMessage}
											type={WarningType.BETWEEN}
											rangeMin={6}
											rangeMax={14}
										/>
									</>
								)}
								<TalosButton
									fieldName="form_submit"
									form={form}
									buttonText="Submit"
									loading={formSubmitMutation.isLoading}
								/>
							</Box>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};
