import { useFlags } from "launchdarkly-react-client-sdk";

export interface TalosFlags {
	uiEnableMmfcFlows: boolean;
	uiEnableUiFlows: boolean;
	uiEnableUtFlow: boolean;
	uiEnableUiUtilitiesSection: boolean;
	uiEnableMhhsRequests: boolean;
	uiEnableForceValidateReads: boolean;
	uiEnableDmex: boolean;
	uiEnableEnergisationStatusUpdate: boolean;
	uiEnableLegacyDataUpdate: boolean;
	talosEnableIncidentBanner:
		| {
				enabled: false;
		  }
		| {
				enabled: true;
				text: string;
				link: string;
		  };
	cmrEnableMdsUpload: boolean;
	cmrEnableUpdateScheduleSalesforceAdapter: boolean;
	requestHeader: string;
}

/**
 * Replaces {@link useFlags} from the LaunchDarkly SDK and provides type safety
 * with our feature flags. The {@link TalosFlags} interface should be kept
 * updated with any new feature flags we add.
 */
export const useTalosFlags = () => {
	return useFlags<TalosFlags>();
};
