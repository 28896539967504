import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../auth";
import { Page } from "../components/pagination/pageable";

export type Severity = "error" | "success" | "info" | "warning";

export interface ITalosAlert {
	message: string;
	severity: Severity;
}

export const defaultAlertState: ITalosAlert = {
	message: "",
	severity: "info",
};

export interface IAlertContext {
	talosAlert: ITalosAlert;
	setTalosAlert: React.Dispatch<React.SetStateAction<ITalosAlert>>;
}

export const AlertContext = React.createContext<IAlertContext>({
	talosAlert: defaultAlertState,
	setTalosAlert: () => {},
});

export function useCountdown(seconds: number) {
	const [secondsLeft, setSecondsLeft] = useState(seconds);

	useEffect(() => {
		if (secondsLeft <= 0) return;

		const timeout = setTimeout(() => {
			setSecondsLeft(secondsLeft - 1);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [secondsLeft]);

	function startCountdown() {
		setSecondsLeft(seconds);
	}
	return { secondsLeft, startCountdown };
}

export const useTalosAlert = (
	message: string | undefined,
	severity: Severity
) => {
	const { setTalosAlert } = useContext(AlertContext);
	const authContext = useContext(AuthContext);

	useEffect(() => {
		let isFetching = false;

		if (!isFetching && message) {
			setTalosAlert({ message, severity });
		}

		return () => {
			isFetching = true;
		};
	}, [authContext, message, severity]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useRecentRequestAlert = <Response>(
	getRecentRequest: () => Promise<Page<Response> | undefined>,
	buildMessage: (requests: Page<Response>) => string,
	severity: Severity
) => {
	const { setTalosAlert } = useContext(AlertContext);

	useEffect(() => {
		let ignore = false;

		getRecentRequest()
			.then((recentRequests) => {
				if (recentRequests?.page.totalItems && !ignore) {
					setTalosAlert({
						message: buildMessage(recentRequests),
						severity: severity,
					});
				}
			})
			.catch((e) => {
				console.debug("Exception retrieving recent Requests", e);
			});

		return () => {
			ignore = true;
		};
	}, [buildMessage, getRecentRequest]); // eslint-disable-line react-hooks/exhaustive-deps
};
