import { BaseFlowResponse } from "./base-flow";
import { DropDownItem } from "./ui-models";

export interface MW4FlowsResponse extends BaseFlowResponse {
	data: MW4FlowsResponseData;
}

export interface MW4FlowsResponseData {
	mpan: string;
	measurementQuantityId: string;
	consumptionAmendmentSupplierReference: string | null;
	consumptionAmendmentPeriodStartDate: string;
	consumptionAmendmentPeriodEndDate: string;
	grossRevisedConsumption: string;
	supplierConsumptionAmendmentReasonCode: string;
}

export interface IFlowFormValues {
	mpan: string;
	measurementQuantityId: string;
	consumptionAmendmentSupplierReference: string | null;
	consumptionAmendmentPeriodStartDate: string;
	consumptionAmendmentPeriodEndDate: string;
	grossRevisedConsumption: string;
	supplierConsumptionAmendmentReasonCode: string;
}

export interface MW4FlowRequest {
	mpan: string;
	measurementQuantityId: string;
	consumptionAmendmentSupplierReference: string | null;
	consumptionAmendmentPeriodStartDate: string;
	consumptionAmendmentPeriodEndDate: string;
	grossRevisedConsumption: string;
	supplierConsumptionAmendmentReasonCode: string;
}

export const MEASUREMENT_QUANTITY_ID: DropDownItem[] = [
	{ display: "Active import", value: "AI" },
	{ display: "Active export", value: "AE" },
	{ display: "Reactive import", value: "RI" },
	{ display: "Reactive export", value: "RE" },
];

export const SUPPLIER_CONSUMPTION_AMENDMENT_REASON_CODE: DropDownItem[] = [
	{ display: "Agreed read", value: "A" },
	{ display: "Estimation issue", value: "E" },
	{ display: "Metering issue", value: "M" },
	{ display: "Reading issue", value: "R" },
	{ display: "Stolen/lost meter", value: "S" },
	{ display: "Tampering/theft", value: "T" },
	{ display: "Other", value: "X" },
];

export const formatMeasurementQuantityIdString = (
	quantityId: string
): string => {
	const enumValue = Object.values(MEASUREMENT_QUANTITY_ID).find(
		(item) => item.value == quantityId
	);

	return enumValue ? enumValue.display : "";
};

export const formatReasonCodeString = (reasonCode: string): string => {
	const enumValue = Object.values(
		SUPPLIER_CONSUMPTION_AMENDMENT_REASON_CODE
	).find((item) => item.value == reasonCode);

	return enumValue ? enumValue.display : "";
};
