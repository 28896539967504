import React, { useContext } from "react";

import { Box, Typography, useTheme } from "@mui/material";
import AirIcon from "@mui/icons-material/Air";
import EngineeringIcon from "@mui/icons-material/Engineering";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import CalculateIcon from "@mui/icons-material/Calculate";
import ListAltIcon from "@mui/icons-material/ListAlt";

import { AuthContext } from "../auth";
import { ROLES } from "../utilities";
import { ActionCard } from "../components";
import { useTalosFlags } from "../flags";

export const Home: React.FC = () => {
	const {
		uiEnableUiUtilitiesSection,
		uiEnableUiFlows,
		uiEnableMmfcFlows,
		uiEnableUtFlow,
		uiEnableMhhsRequests,
		uiEnableForceValidateReads,
		uiEnableDmex,
		uiEnableEnergisationStatusUpdate,
		uiEnableLegacyDataUpdate,
	} = useTalosFlags();

	const { hasRole } = useContext(AuthContext);

	const theme = useTheme();

	return (
		<Box data-cy="dashboard" maxWidth={800}>
			{hasRole(ROLES.BOOKING_REQUEST_READ) ||
			hasRole(ROLES.BOOKING_REQUEST_WRITE) ? (
				<>
					<Typography variant="h2" gutterBottom data-cy="dashboard-msd-panel">
						MDS Appointment Booking
					</Typography>

					<Box className="action-wrapper">
						{hasRole(ROLES.BOOKING_REQUEST_WRITE) && (
							<ActionCard
								path="/new-booking-request"
								icon={<EngineeringIcon fontSize="large" />}
								backgroundColor={theme.palette.primary.main}
								title="Add Request"
								text="
              The form for adding a new booking request."
							/>
						)}
						{hasRole(ROLES.BOOKING_REQUEST_READ) && (
							<ActionCard
								path="/booking-requests"
								backgroundColor={theme.palette.primary.dark}
								icon={<MenuBookIcon fontSize="large" />}
								title="View Requests"
								text="
				This page shows a list of booking requests and their current status."
							/>
						)}
					</Box>
				</>
			) : null}

			{hasRole(ROLES.CHANGE_READ_READ) || hasRole(ROLES.CHANGE_READ_WRITE) ? (
				<>
					<Typography
						variant="h2"
						gutterBottom
						sx={{ marginTop: "40px" }}
						data-cy="dashboard-change-read-panel"
					>
						Change Read Request
					</Typography>

					<Box className="action-wrapper">
						{hasRole(ROLES.CHANGE_READ_WRITE) && (
							<ActionCard
								path="/add-change-read-request"
								icon={<ChangeCircleIcon fontSize="large" />}
								backgroundColor={theme.palette.info.main}
								title="Add Request"
								text="
              The form for adding a new change read request."
							/>
						)}
						{hasRole(ROLES.CHANGE_READ_READ) && (
							<ActionCard
								path="/change-reads"
								icon={<PublishedWithChangesIcon fontSize="large" />}
								backgroundColor={theme.palette.info.dark}
								title="Change Reads"
								text="
				A list of all change read requests."
							/>
						)}
					</Box>
				</>
			) : null}

			<>
				{uiEnableForceValidateReads &&
				(hasRole(ROLES.SETTLEMENTS_READS_FVR_READ) ||
					hasRole(ROLES.SETTLEMENTS_READS_FVR_WRITE)) ? (
					<>
						<Typography
							variant="h2"
							gutterBottom
							data-cy="dashboard-force-validate-read-panel"
						>
							Force Validate Read Requests
						</Typography>
						<Box className="action-wrapper">
							{hasRole(ROLES.SETTLEMENTS_READS_FVR_WRITE) && (
								<ActionCard
									path="/add-force-validate-read"
									icon={<ChangeCircleIcon fontSize="large" />}
									backgroundColor={theme.palette.talos.colour.purple.main}
									title="Add Force Validate Read"
									text="
              The form for adding a new Force Validate Read request."
								/>
							)}
							{hasRole(ROLES.SETTLEMENTS_READS_FVR_READ) && (
								<ActionCard
									path="/force-validate-read-requests"
									icon={<PublishedWithChangesIcon fontSize="large" />}
									backgroundColor={theme.palette.talos.colour.purple.darker}
									title="Force Validate Read Requests"
									text="A list of all Force Validate Read requests."
								/>
							)}
						</Box>
					</>
				) : null}
			</>

			<>
				{uiEnableDmex &&
				(hasRole(ROLES.SETTLEMENTS_READS_DMEX_READ) ||
					hasRole(ROLES.SETTLEMENTS_READS_DMEX_WRITE)) ? (
					<>
						<Typography
							variant="h2"
							gutterBottom
							data-cy="dashboard-dmex-panel"
						>
							DMEX Requests
						</Typography>
						<Box className="action-wrapper">
							{hasRole(ROLES.SETTLEMENTS_READS_DMEX_WRITE) && (
								<ActionCard
									path="/add-dmex"
									icon={<ChangeCircleIcon fontSize="large" />}
									backgroundColor={theme.palette.talos.colour.purple.main}
									title="Add DMEX"
									text="
              The form for adding a new DMEX request."
								/>
							)}
							{hasRole(ROLES.SETTLEMENTS_READS_DMEX_READ) && (
								<ActionCard
									path="/dmex-requests"
									icon={<PublishedWithChangesIcon fontSize="large" />}
									backgroundColor={theme.palette.talos.colour.purple.darker}
									title="DMEX Requests"
									text="A list of all DMEX requests."
								/>
							)}
						</Box>
					</>
				) : null}
			</>

			{uiEnableUiFlows &&
			(hasRole(ROLES.FLOWS_USER) || hasRole(ROLES.FLOWS_ADMIN)) ? (
				<>
					<Typography variant="h2" gutterBottom data-cy="dashboard-d0205-panel">
						D0205 Flows
					</Typography>

					<Box className="action-wrapper">
						{hasRole(ROLES.FLOWS_ADMIN) && (
							<ActionCard
								path="/add-d0205-flow"
								icon={<LibraryAddIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.blue.light}
								title="Add D0205 Flow"
								text="
              The form for adding a new D0205 flow."
							/>
						)}
						{hasRole(ROLES.FLOWS_USER) && (
							<ActionCard
								path="/d0205-flows"
								backgroundColor={theme.palette.talos.colour.blue.dark}
								icon={<AirIcon fontSize="large" />}
								title="View D0205 flows"
								text="
				This page shows a list of D0205 flows and their current status."
							/>
						)}
					</Box>
				</>
			) : null}

			{uiEnableUiFlows &&
			(hasRole(ROLES.FLOWS_USER) || hasRole(ROLES.FLOWS_ADMIN)) ? (
				<>
					<Typography variant="h2" gutterBottom data-cy="dashboard-d0052-panel">
						D0052 Flows
					</Typography>

					<Box className="action-wrapper">
						{hasRole(ROLES.FLOWS_ADMIN) && (
							<ActionCard
								path="/add-d0052-flow"
								icon={<LibraryAddIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.red.light}
								title="Add D0052 Flow"
								text="
              The form for adding a new D0052 flow."
							/>
						)}
						{hasRole(ROLES.FLOWS_USER) && (
							<ActionCard
								path="/d0052-flows"
								backgroundColor={theme.palette.talos.colour.red.dark}
								icon={<AirIcon fontSize="large" />}
								title="View D0052 flows"
								text="
				This page shows a list of D0052 flows and their current status."
							/>
						)}
					</Box>
				</>
			) : null}

			{uiEnableUtFlow &&
			(hasRole(ROLES.FLOWS_USER) || hasRole(ROLES.FLOWS_ADMIN)) ? (
				<>
					<Typography variant="h2" gutterBottom data-cy="dashboard-ut003-panel">
						Gas Read Replacement Requests
					</Typography>

					<Box className="action-wrapper">
						{hasRole(ROLES.FLOWS_ADMIN) && (
							<ActionCard
								path="/add-ut003-flow"
								icon={<LibraryAddIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.pink.light}
								title="Request Gas Read Replacement"
								text="The form for requesting a Gas Read Replacement."
							/>
						)}
						{hasRole(ROLES.FLOWS_USER) && (
							<ActionCard
								path="/ut003-flows"
								backgroundColor={theme.palette.talos.colour.pink.dark}
								icon={<AirIcon fontSize="large" />}
								title="View Gas Read Replacement Requests"
								text="This page shows a list of Gas Read Replacement Requests and their current status."
							/>
						)}
					</Box>
				</>
			) : null}

			{uiEnableMmfcFlows &&
			(hasRole(ROLES.FLOWS_USER) || hasRole(ROLES.FLOWS_ADMIN)) ? (
				<>
					<Typography variant="h2" gutterBottom data-cy="dashboard-s0003-panel">
						S0003 Flows
					</Typography>

					<Box className="action-wrapper">
						{hasRole(ROLES.FLOWS_ADMIN) && (
							<ActionCard
								path="/add-mw2-flow"
								icon={<LibraryAddIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.teal.light}
								title="New Consumption Update Request"
								text="
              The form for adding a new Consumption Update Request."
							/>
						)}
						{hasRole(ROLES.FLOWS_USER) && (
							<ActionCard
								path="/mw2-flows"
								backgroundColor={theme.palette.talos.colour.teal.dark}
								icon={<AirIcon fontSize="large" />}
								title="View Consumption Update Requests"
								text="
				This page shows a list of Consumption Update Requests and their current status."
							/>
						)}
					</Box>

					<Box className="action-wrapper">
						{hasRole(ROLES.FLOWS_ADMIN) && (
							<ActionCard
								path="/add-mw4-flow"
								icon={<LibraryAddIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.orange.light}
								title="Request Consumption Amendment"
								text="
              The form for requesting a Consumption Amendment."
							/>
						)}
						{hasRole(ROLES.FLOWS_USER) && (
							<ActionCard
								path="/mw4-flows"
								backgroundColor={theme.palette.talos.colour.orange.dark}
								icon={<AirIcon fontSize="large" />}
								title="View Consumption Amendment Requests"
								text="This page shows a list of Consumption Amendment Requests and their current status."
							/>
						)}
					</Box>
					<Box className="action-wrapper">
						{hasRole(ROLES.FLOWS_ADMIN) && (
							<ActionCard
								path="/add-s33-meter-reading"
								icon={<LibraryAddIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.purple.light}
								title="Send Meter Reading to Industry"
								text="
              The form for adding a new Meter Reading to Industry (S33 flow)."
							/>
						)}
						{hasRole(ROLES.FLOWS_ADMIN) && (
							<ActionCard
								path="/add-s33-read-override"
								icon={<LibraryAddIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.purple.light}
								title="Send Read Override"
								text="
              The form for adding a new Read Override (S33 flow)."
							/>
						)}
						{hasRole(ROLES.FLOWS_USER) && (
							<ActionCard
								path="/s33-flows"
								backgroundColor={theme.palette.talos.colour.purple.dark}
								icon={<AirIcon fontSize="large" />}
								title="View S33 flows"
								text="
				This page shows a list of S33 flows (Meter Readings to Industry and Read Overrides) and their current status."
							/>
						)}
					</Box>
				</>
			) : null}
			{hasRole(ROLES.EXPORT_REGISTRATION_READ) ||
			hasRole(ROLES.EXPORT_REGISTRATION_BULK_WRITE) ? (
				<>
					<Typography
						variant="h2"
						gutterBottom
						sx={{ marginTop: "40px" }}
						data-cy="dashboard-registrations-panel"
					>
						Export Registrations
					</Typography>

					<Box className="action-wrapper">
						{hasRole(ROLES.EXPORT_REGISTRATION_BULK_WRITE) && (
							<ActionCard
								path="/export/migrations"
								icon={<ListAltIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.grey.main}
								title="Migrate Registrations"
								text="Upload file to start migration registrations."
							/>
						)}
						{hasRole(ROLES.EXPORT_REGISTRATION_BULK_WRITE) && (
							<ActionCard
								path="/export/ecoes"
								icon={<ListAltIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.red.main}
								title="Plan ECOES Import"
								text="Upload ECOES file to build import plan."
							/>
						)}
						{hasRole(ROLES.EXPORT_REGISTRATION_READ) && (
							<ActionCard
								path="/export/registrations"
								icon={<ListAltIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.grey.dark}
								title="View Registrations"
								text="A list of all registration journeys."
							/>
						)}
					</Box>
				</>
			) : null}

			{uiEnableMhhsRequests && hasRole(ROLES.OUTBOUND_MTD_WRITE) ? (
				<>
					<Typography
						variant="h2"
						gutterBottom
						sx={{ marginTop: "40px" }}
						data-cy="dashboard-outbound-panel"
					>
						Outbound
					</Typography>

					<Box className="action-wrapper">
						{hasRole(ROLES.OUTBOUND_MTD_WRITE) && (
							<ActionCard
								path="/outbound/submit-mop04"
								icon={<ListAltIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.green.main}
								title="Submit MOP04"
								text="Submit a mop04 form."
							/>
						)}
					</Box>
				</>
			) : null}

			{(uiEnableMhhsRequests ||
				uiEnableEnergisationStatusUpdate ||
				uiEnableLegacyDataUpdate) &&
			hasRole(ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE) ? (
				<>
					<Typography
						variant="h2"
						gutterBottom
						sx={{ marginTop: "40px" }}
						data-cy="dashboard-change-registration-data-panel"
					>
						Change of Registration Data
					</Typography>

					<Box className="action-wrapper">
						{uiEnableLegacyDataUpdate &&
							hasRole(ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE) && (
								<ActionCard
									path="/change-registration/legacy-data"
									icon={<ListAltIcon fontSize="large" />}
									backgroundColor={theme.palette.talos.colour.yellow.darker}
									title="Legacy Data Item"
									text="Submit update legacy data item form."
								/>
							)}
						{uiEnableMhhsRequests &&
							hasRole(ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE) && (
								<ActionCard
									path="/change-registration/ihd-status"
									icon={<ListAltIcon fontSize="large" />}
									backgroundColor={theme.palette.talos.colour.yellow.dark}
									title="IHD Status"
									text="Submit update IHD status form."
								/>
							)}
						{uiEnableMhhsRequests &&
							hasRole(ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE) && (
								<ActionCard
									path="/change-registration/consent-granularity"
									icon={<ListAltIcon fontSize="large" />}
									backgroundColor={theme.palette.talos.colour.yellow.darkest}
									title="Consent Granularity"
									text="Submit update Consent Granularity form."
								/>
							)}
						{uiEnableMhhsRequests &&
							hasRole(ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE) && (
								<ActionCard
									path="/change-registration/request-supply-deactivation"
									icon={<ListAltIcon fontSize="large" />}
									backgroundColor={theme.palette.talos.colour.blue.darkest}
									title="Supply Deactivation"
									text="Request supply deactivation."
								/>
							)}
						{uiEnableMhhsRequests &&
							hasRole(ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE) && (
								<ActionCard
									path="/change-registration/request-supply-disconnection"
									icon={<ListAltIcon fontSize="large" />}
									backgroundColor={theme.palette.talos.colour.grey.darker}
									title="Supply Disconnection"
									text="Request supply disconnection."
								/>
							)}
						{uiEnableEnergisationStatusUpdate &&
							hasRole(ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE) && (
								<ActionCard
									path="/change-registration/update-energisation-status"
									icon={<ListAltIcon fontSize="large" />}
									backgroundColor={theme.palette.talos.colour.green.darker}
									title="Energisation Status"
									text="Request update of energisation status."
								/>
							)}
						{uiEnableMhhsRequests &&
							hasRole(ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE) && (
								<ActionCard
									path="/change-registration/request-install-remove-meter"
									icon={<ListAltIcon fontSize="large" />}
									backgroundColor={theme.palette.talos.colour.blue.darker}
									title="Install/Remove Meter/s"
									text="Request install/remove meter/s."
								/>
							)}
					</Box>
				</>
			) : null}

			{uiEnableMhhsRequests &&
			(hasRole(ROLES.FLOWS_USER) || hasRole(ROLES.FLOWS_ADMIN)) ? (
				<>
					<Typography variant="h2" gutterBottom data-cy="dashboard-si2-panel">
						Metering Point Address Amendment Flows
					</Typography>
					<Box className="action-wrapper">
						{hasRole(ROLES.FLOWS_ADMIN) && (
							<ActionCard
								path="/add-si2-flow"
								icon={<ChangeCircleIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.pink.main}
								title="Add Request"
								text="The form for requesting a metering point address update."
							/>
						)}
						{hasRole(ROLES.FLOWS_USER) && (
							<ActionCard
								path="/si2-flows"
								icon={<PublishedWithChangesIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.pink.darkest}
								title="Metering Point Address Update Requests"
								text="A list of all metering point address update requests."
							/>
						)}
					</Box>
				</>
			) : null}

			{uiEnableMhhsRequests &&
			(hasRole(ROLES.FLOWS_USER) || hasRole(ROLES.FLOWS_ADMIN)) ? (
				<>
					<Typography variant="h2" gutterBottom data-cy="dashboard-f09-panel">
						Domestic Premise Indicator Update Flows
					</Typography>
					<Box className="action-wrapper">
						{hasRole(ROLES.FLOWS_ADMIN) && (
							<ActionCard
								path="/add-f09-flow"
								icon={<ChangeCircleIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.purple.main}
								title="Add Flow"
								text="
              The form for adding a new domestic premise indicator update flow."
							/>
						)}
						{hasRole(ROLES.FLOWS_USER) && (
							<ActionCard
								path="/f09-flows"
								icon={<PublishedWithChangesIcon fontSize="large" />}
								backgroundColor={theme.palette.talos.colour.purple.darker}
								title="Domestic Premise Indicator Update Flows"
								text="
				A list of all domestic premise indicator update flows."
							/>
						)}
					</Box>
				</>
			) : null}

			{uiEnableUiUtilitiesSection && hasRole(ROLES.UTILITIES_ACCESS) ? (
				<>
					<Typography
						variant="h2"
						gutterBottom
						sx={{ marginTop: "40px" }}
						data-cy="dashboard-utilities-panel"
					>
						Utilities
					</Typography>

					<Box className="action-wrapper">
						<ActionCard
							path="/reads-calculations"
							icon={<CalculateIcon fontSize="large" />}
							backgroundColor={theme.palette.warning.main}
							title="Calculations"
							text="Tools for calculating reads."
						/>
					</Box>
				</>
			) : null}
		</Box>
	);
};
