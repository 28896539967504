import { BaseFlowResponse } from "./base-flow";
import { DropDownItem } from "./ui-models";

export interface MW2FlowsResponse extends BaseFlowResponse {
	data: MW2FlowsResponseData;
}

export interface MW2FlowsResponseData {
	mpan: string;
	supplierNotificationType: string;
	supplierNotificationEffectiveFromDate: string;
	supplierNotificationExpiryDate: string | null;
	meterIdSerialNumber: string;
	additionalInformation: string | null;
	supplierNominatedAnnualConsumption: string;
}

export interface IFlowFormValues {
	mpan: string;
	supplierNotificationType: string;
	supplierNotificationEffectiveFromDate: string;
	supplierNotificationExpiryDate: string | null;
	meterIdSerialNumber: string | null;
	additionalInformation: string | null;
	supplierNominatedAnnualConsumption: string | null;
}

export interface MW2FlowRequest {
	mpan: string;
	supplierNotificationType: string;
	supplierNotificationEffectiveFromDate: string;
	supplierNotificationExpiryDate: string | null;
	meterIdSerialNumber: string | null;
	additionalInformation: string | null;
	supplierNominatedAnnualConsumption: string | null;
}

export const SUPPLIER_TYPES: DropDownItem[] = [
	{ display: "SNVT", value: "SNVT" },
	{ display: "SNNC", value: "SNNC" },
	{ display: "SNRE", value: "SNRE" },
	{ display: "SNRD", value: "SNRD" },
	{ display: "SNAC", value: "SNAC" },
];
