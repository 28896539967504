export const READ_TYPES = [
	"A",
	"B",
	"C",
	"D",
	"F",
	"I",
	"M",
	"O",
	"P",
	"Q",
	"R",
	"S",
	"T",
	"W",
	"Z",
];

const CHANGE_READ_STATUS = ["PENDING", "SENT", "FAILED", "CANCELLED"];

export type ReadType = (typeof READ_TYPES)[number];
export type ChangeReadStatus = (typeof CHANGE_READ_STATUS)[number];

export interface ChangeReadRequest {
	mpan: string;
	msn: string;
	readDate: string;
	readType: ReadType;
	firstRegisterId: string;
	firstRegisterIncorrectRead: number;
	firstRegisterCorrectRead: number;
	secondRegisterId?: string;
	secondRegisterIncorrectRead?: number;
	secondRegisterCorrectRead?: number;
}

export interface IChangeReadFormValues {
	mpan: string;
	msn: string;
	readDate: string;
	readType: ReadType | "";
	firstRegisterId: string;
	firstRegisterIncorrectRead: number | "";
	firstRegisterCorrectRead: number | "";
	subRegisters: "Y" | "N";
	secondRegisterId?: string;
	secondRegisterIncorrectRead: number | "";
	secondRegisterCorrectRead: number | "";
}

export interface ChangeReadResponse {
	id: string;
	changeRead: ChangeReadRequest;
	dateTimeRequestReceived: string;
	dateTimeFileGenerated?: string;
	status: ChangeReadStatus;
	agentName: string;
	agentEmail: string;
	errors?: string[];
}
