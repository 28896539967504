export enum SettlementsReadsStatus {
	NEW,
	PROCESSED,
	FAILED_ONCE,
	FAILED_TWICE,
	FAILED_THRICE,
	FAILED,
	REJECTED,
}

export interface BaseSettlementsReadsResponse {
	id: string;
	agentName: string;
	mpan: number;
	msn: string;
	readDate: string;
	createdAt: string;
	status: SettlementsReadsStatus;
}
