import * as Yup from "yup";

import { usefulRegex } from "../../utilities";

const mpxnValidation = (filterType: string) => {
	if (filterType == "MPAN")
		return {
			mpxn: Yup.string().matches(
				usefulRegex.MPAN,
				`${filterType} must be a 13 digit integer`
			),
		};
	else if (filterType == "MPRN")
		return {
			mpxn: Yup.string().matches(
				usefulRegex.MPRN,
				`${filterType} must be between 6 and 10 integers`
			),
		};
	else
		return {
			mpxn: Yup.string()
				.min(6, `${filterType} must between 6 and 13 integers`)
				.max(13, `${filterType} must between 6 and 13 integers`),
		};
};

export const validationSchema = (filterType: string) =>
	Yup.object({
		...mpxnValidation(filterType),
		start: Yup.date().typeError("Start date must be a valid date").nullable(),
		end: Yup.date().typeError("Start date must be a valid date").nullable(),
	});
